@import '../mixins/breakpoints.scss';

@mixin overflow() {
  overflow: hidden;
  /* For ios */
  touch-action: none;
  -webkit-overflow-scrolling: none;
  /* Other browsers */
  overscroll-behavior: none;
}

.disableScrollForModalWithKeyboards {
  @include mobile {
    @include overflow();
    position: fixed;

    // disable the app element since in mobile IOS when keyboard is open its still scrollable
    & > #__next,
    & > #root {
      @include overflow();
      position: fixed;
    }
  }
}

.disableScroll {
  @include overflow();
}

.disableScrollMobile {
  @include mobile {
    @include overflow();
  }
}

body[class*='disableScrollMobilePopupWithKeyboard'] {
  @include mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.disableScrollDesktop {
  @include desktop {
    @include overflow();
  }
}
