
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

@import '@y2/mango/mixins';
@import '../scss-variables.scss';

.common-header {
  display: flex;
  background-color: rgb(var(--white));
  position: relative;
  z-index: calc(var(--header-index) + 10);

  &.hasShadow {
    box-shadow: var(--shadow-level-2);
  }

  .common-button-container {
    align-self: inherit;
    display: flex;
    margin: auto 0;
  }

  .bottom-menu-container {
    flex-basis: calc(100% + #{$header-desktop-padding * 2});

    margin: 0 calc(-1 * #{$header-desktop-padding});
  }

  .mobile-close-button {
    position: absolute;
    display: flex;
    right: 0;
    top: 0;
    padding: 12px;
    z-index: 12;
    font-size: var(--font-size-xl);

    .close-icon {
      fill: rgb(var(--jet));
    }
  }

  @include desktop {
    flex-wrap: wrap;

    min-height: calc(#{$desktop-content-height} + #{$desktop-border-height});
    padding: 0 $header-desktop-padding;

    &.is-sticky {
      position: sticky;
      top: 0;
    }

    &.translucent {
      opacity: $translucent-value;
    }

    .logo-slot {
      min-height: 100%;
    }

    .common-nav-container {
      margin-left: auto;
    }

    .mobile-close-button {
      display: none;
    }

    .user-menu-extension {
      display: flex;
      flex-direction: row;
    }
  }

  @include mobile {
    --menu-width: 315px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: calc(var(--menu-width) * -1);
    width: var(--menu-width);
    transition: right 0.3s;
    flex-direction: row;
    align-content: baseline;
    flex-wrap: wrap;
    padding: 0;
    min-height: 100%;
    overflow-y: auto;

    &.is-open {
      right: 0;
    }

    .user-menu-extension {
      display: none;
    }

    .logo-slot {
      display: none;
    }

    .common-nav-container {
      order: 5;
      border-top: 1px solid #e5e5e5;
      margin-top: 10px;
    }

    .common-button-container {
      order: 3;
    }

    .bottom-menu-container {
      order: 6;

      margin: 0 calc(-1 * #{$mobile-side-padding});
    }

    .rows-in-mobile-menu {
      flex: 1 1 100%;
      width: 100%;
      margin-bottom: 10px;
    }

    .grey-middle-split-background {
      position: relative;

      &::after {
        content: '';
        background-color: #f8f8f8;
        height: 700px;
        width: 100%;
        position: absolute;
        z-index: -1;
        bottom: 50%;
        left: 0;
      }
    }
  }
}

.common-mask {
  display: none;
  z-index: calc(var(--header-index) + 5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(var(--black), 0.4);
  overflow: hidden;

  @include mobile {
    &.is-visible {
      display: block;
    }
  }
}
