$desktop-border-height: 5px;
$desktop-content-height: 59px;

$desktop-header-height: $desktop-content-height + $desktop-border-height;
$header-desktop-padding: 20px;
$header-mobile-padding: 10px;
$mobile-side-padding: 20px;
$mobile-top: 30px;
$mobile-header-size: 45px;
$translucent-value: 0.96;

$wide-desktop-for-buttons-breakpoint: 1450px;
$mobile-for-buttons-breakpoint: 880px;
