
  @import '@y2/mango/mixins';
  $envName: "production";
  $y2AssetsPath: "https://assets.yad2.co.il/";
  $drushimAssetsPath: "http://assets.drushim.co.il/";

$gap: 20px;

@import '../breakpoints.scss';

.content {
  display: flex;
  flex-wrap: wrap;
  gap: $gap;
  padding: 25px 32px;
  color: rgb(var(--jet));

  &:not(.visible) {
    display: none;
  }

  .headerWrapper {
    width: 100%;

    .header {
      display: block;
      font-size: pxToRem(20);
      line-break: pxToRem(28);
      margin-bottom: 20px;

      .icon {
        line-height: 0;
        margin-top: 2px;
        font-size: pxToRem(16);
      }

      * {
        display: inline-block;
        vertical-align: middle;
      }

      strong {
        font-weight: 500;
      }
    }
  }

  .subCategoryItem {
    width: calc((100% / 3) - ($gap * 2 / 3));

    /* stylelint-disable-next-line custom/enforce-breakpoint-mixin */
    @media screen and (max-width: #{$wide-desktop-for-recommerce-menu}) {
      width: calc(50% - ($gap / 2));
    }

    /* stylelint-disable-next-line custom/enforce-breakpoint-mixin */
    @media screen and (max-width: #{$small-desktop-for-recommerce-menu}) {
      width: 100%;
    }
  }
}
